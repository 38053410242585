<template>
  <main>
    <template>
      <b-row>
        <b-col sm="12" md="12" lg="12" xl="12">
          <!-- <dashboard-ceo /> -->
        </b-col>
      </b-row>
    </template>
    <template>
      <b-row>
        <b-col sm="12" md="12" lg="12" xl="12">
          <!-- <dashboard-headwh /> -->
        </b-col>
      </b-row>
    </template>
    <template >
      <template>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <dashboard-penerimaan />
            </b-col>
        </b-row>
      </template>
    </template>
  </main>
</template>
<script>
import { BAlert, BAvatar, BRow, BCol, BCard, BFormInput, BButton } from "bootstrap-vue";
import SedangPerbaikan from "./../../../SedangPerbaikan.vue"
import DashboardPenerimaan from "../DashboardPenerimaan.vue";
export default {
  data() {
    return {
      level: JSON.parse(localStorage.getItem("userData")).level,
      rekapKomisi: null,
      rekapPoin: null,
      rekapKomisiCabang: null,
    };
  },
  components: {
    DashboardPenerimaan,
    SedangPerbaikan,
    BAlert,
    BAvatar,
    BRow,
    BCol,
    BCard,
    BFormInput,
    BButton,
  },
  computed: {
    myName() {
      if (this.user) {
        return this.user.karyawan ? this.user.karyawan.nama_lengkap : this.user.email;
      }

      return null;
    },

    cekAffiliate() {
      let level = this.user.level.nama_level;
      if (level.toUpperCase() == "CABANG" || level.toUpperCase() == "MARKETING") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    copyUrl(link) {
      navigator.clipboard.writeText(`${link}`);
      this.displaySuccess({
        message: "Berhasil disalin",
      });
    },
    getRekapData() {
      let payload = {
        member_id: this.user.karyawan.id,
        type: "affiliate",
      };
      this.$store
        .dispatch("komisi/rekapKomisi", payload)
        .then((res) => {
          this.rekapKomisi = res;
        })
        .catch((e) => {
          console.log(e);
          // this.displayError(e);
        });
    },
    getRekapDataCabang() {
      let payload = {
        member_id: this.user.karyawan.id,
        type: "cabang",
      };
      this.$store
        .dispatch("komisi/rekapKomisi", payload)
        .then((res) => {
          this.rekapKomisiCabang = res;
        })
        .catch((e) => {
          console.log(e);
          // this.displayError(e);
        });
    },
    getRekapDataPoin() {
      let payload = {
        member_id: this.user.karyawan.id,
        type: "affiliate",
      };
      this.$store
        .dispatch("poin/getRekap", payload)
        .then((res) => {
          this.rekapPoin = res;
        })
        .catch((e) => {
          console.log(e);
          // this.displayError(e);
        });
    },
  },
  created() {
    if (this.isOwner || this.isCEO || this.isFinance) {

      return false;
    } else {
      // this.getRekapData();
      // this.getRekapDataCabang();
      // this.getRekapDataPoin();
    }
  },
};
</script>
